import React, { useState, useEffect } from "react";
import ReactHtmlParser from "react-html-parser";

import { getUbicacionSucursales } from "../Repository";
import global from "../global";

const LocationItem = (props) => {
  const [sucursales, setSucursales] = useState([]);

  useEffect(() => {    

    const obtieneSucursales = () => {
      getUbicacionSucursales(props.ordenGrupo)
        .then((response) => {
          if (response.status === 200) {
            setSucursales(response.data.result);
          } else {
            alert("Error obtener la información");
            setSucursales([]);
          }
        })
        .catch(
          (err) => alert("Error obtener la información " + err),
          setSucursales([])
        );
    };

    obtieneSucursales();
  }, [props.ordenGrupo]);

  return (
    <div className="row">
      {sucursales.map((item) => {
        return (
          <div
            className="col-md-4 card-group"
            name="divCardGroup"
            key={item.idUbicacion}
          >
            <div className="card mb-4" name="cardFluid">
              <div className="card-header text-center" name="divCardHeader">
                {item.Sucursal}
              </div>
              <div className="card-body" name="divCardBody">
                <p className="card-text fluid" name="parrafo">
                  {ReactHtmlParser(item.Direccion)}
                </p>
              </div>
              <div className="card-footer text-center" name="divCardFooter">
                <a
                  name="aLink"
                  target="_new"
                  className="btn btn-warning btn-sm mb-2 font-weight-normal"
                  role="button"
                  href={
                    global.google_maps_URL +
                    item.Coordenadas.split(",")[0] +
                    "+" +
                    item.Coordenadas.split(",")[1]
                  }
                >
                  <i className="fas fa-car mr-1"></i>
                  Indicaciones
                </a>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default LocationItem;
