import React, { useState, useEffect, Fragment } from "react";

import ProductItems from "../components/ProductItems";
import { getFamiliasImg } from "../Repository";
import Menu from "../components/Menu";
import Footer from "../components/Footer";
import global from "../global";

const Products = () => {
  const [familias, setFamilias] = useState([]);

  const obtieneFamiliasInicio = () => {
    getFamiliasImg()
      .then((response) => {
        if (response.status === 200) {
          setFamilias(response.data.result);
        } else {
          alert("Error obtener la información del catalogo de  [Ubicaciones]");
          setFamilias([]);
        }
      })
      .catch(
        (err) => alert("Error obtener la información [Ubicaciones] " + err),
        setFamilias([])
      );
  };

  useEffect(() => {
    obtieneFamiliasInicio();
  }, []);

  return (
    <Fragment>
      <Menu />
      <section className="page-section" id="products">
        <div className="container" name="container">
          <div className="col-lg-12 text-center" name="divcol">
            <h2 className="section-heading text-uppercase">Productos</h2>
            <h3 className="section-subheading text-muted mb-5">
              {global.leyendaProductos}
            </h3>
          </div>

          <div className="container">
            <div className="row mt-3">
              <div className="col-md-12">
                <nav>
                  <div
                    className="nav nav-tabs bg-light"
                    id="nav-tab"
                    name="navtab"
                    role="tablist"
                  >
                    <Fragment>
                      {familias.map((item) => (
                        <a
                          className={
                            item
                              ? item.idFamilia < 2
                                ? "nav-item nav-link active"
                                : "nav-item nav-link"
                              : "nav-item nav-link"
                          }
                          id={item.strDescripcion + "tab"}
                          data-toggle="tab"
                          href={"#nav-" + item.idFamilia}
                          role="tab"
                          key={item.strDescripcion}
                          name={item.strDescripcion}
                          aria-controls={"nav-" + item.idFamilia}
                          aria-selected={
                            item
                              ? item.idFamilia < 2
                                ? "true"
                                : "false"
                              : "false"
                          }
                        >
                          {item.strDescripcion}
                        </a>
                      ))}
                    </Fragment>
                  </div>
                </nav>
                <div
                  className="tab-content"
                  name="tabcontent"
                  id="nav-tabContent"
                >
                  {familias.map((item, index) => (
                    <div
                      className={
                        item
                          ? item.idFamilia < 2
                            ? "tab-pane fade show active"
                            : "tab-pane fade"
                          : "tab-pane fade"
                      }
                      id={"nav-" + item.idFamilia}
                      role="tabpanel"
                      name="tabpanel"
                      key={item.strDescripcion}
                      aria-labelledby={"nav-" + item.idFamilia + "-tab"}
                    >
                      <ProductItems key={index} idFamilia={item.idFamilia} />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </Fragment>
  );
};

export default Products;
