import React, { useState, useEffect } from "react";
import imageExists from "image-exists";

import Global from "../global";
import ProductApplications from "./ProductApplications";
import Modal360Viewer from "./Product360Viewer";
import ImageZoomViewer from "./ProductImageZoomViewer";
import Comments from "./Comments";


const CatalogueProductItem = (props) => {
  const item = props.item;
  const index = props.index;
  const [imageSource, setImageSource] = useState("");
  const [is360, set360] = useState(false);
  const [isModal360Open, setIsModal360Open] = useState(false);
  const [codeSelected, setCodeSelected] = useState(null);
  const [commentSelected, setCommentSelected] = useState(null);
  const [isImageZoom, setIsImageZoom] = useState(false);

  useEffect(() => {
    renderImageSource(item.Codigo);
  }, [item.Codigo]);

  const openImageZoomModal = () => {
    if (imageSource !== Global.foto_nd) setIsImageZoom(true);
  };

  const closeImageZoomModal = () => {
    setIsImageZoom(false);
  };

  const openAppsModal = (e) => {
    e.preventDefault();
    setCodeSelected(item.Codigo);
  };


  const openComments = (e) => {
    e.preventDefault();
    setCommentSelected(item.Codigo);
  };

  const closeAppsModal = () => {
    setCodeSelected(null);
  };

  const open360Modal = (e) => {
    e.preventDefault();
    setIsModal360Open(true);
  };

  const close360Modal = () => {
    setIsModal360Open(false);
  };

  const renderAvailability = (available) => {
    const badge = "badge ";

    switch (available) {
      case "Disponible":
        return badge + "badge-success";
      case "Consulta":
        return badge + "badge-light";
      case "Próximamente":
        return badge + "badge-warning";
      default:
        break;
    }
  };

  const renderImageSource = (codigo) => {
    imageExists(
      Global.urlFotos360 + codigo.toUpperCase() + "/images/img_0_0_0.jpg",
      function (exists) {
        if (exists) {
          setImageSource(
            Global.urlFotos360 + codigo.toUpperCase() + "/images/img_0_0_0.jpg"
          );
          set360(true);
        } else {
          imageExists(
            Global.fotos_URL + codigo.toLowerCase().replace("-", "_") + ".jpg",
            function (exists) {
              if (exists) {
                setImageSource(
                  Global.fotos_URL +
                    codigo.toLowerCase().replace("-", "_") +
                    ".jpg"
                );
              } else {
                setImageSource(Global.foto_nd);
              }
            }
          );
        }
      }
    );
  };

  return (
    <div className="col-md-4 card-group">
      <div className="card mb-4">
        <div className="card-header text-center">
          {item.Familia}
          {item.Nuevo ? (
            <span className="badge rounded-pill badge-primary ml-2">Nuevo</span>
          ) : (
            <span></span>
          )}
        </div>
        <img
          className="card-img-top"
          style={{
            cursor: "pointer",
            maxWidth: 100 + "%",
            height: 170 + "px",
            objectFit: is360 ? "cover" : "contain",
          }}
          src={imageSource}
          alt={item.Codigo}
          onClick={() => openImageZoomModal()}
          data-toggle="modal"
          data-target={"#modalImageZoom" + item.Codigo}
        />
        {isImageZoom && (
          <ImageZoomViewer
            item={item}
            imageSource={imageSource}
            closeModal={closeImageZoomModal}
          />
        )}
        {codeSelected && (
          <ProductApplications
            codigo={item.Codigo}
            closeModal={closeAppsModal}
          />
        )}
        {commentSelected && (
          <Comments
            codigo={item.Codigo}
            closeModal={closeAppsModal}
          />
        )}
        <div className="card-body">
          <h5 className="card-title">
            <div className="row">
              <div className="col-xs">
                <div className="float-left">
                  <span className="badge badge-secondary">{item.Codigo}</span>
                </div>
              </div>
              <div className="col text-center">
             {/*   <button
                  className="btn btn-outline-warning btn-sm rounded-pill mr-2"
                  onClick={(e) => openAppsModal(e)}
                  data-toggle="modal"
                  data-target={"#modalApps" + item.Codigo}
                >
                  APS
                </button>
                 */}

                <button
                  className="btn btn-outline-danger btn-sm rounded-pill rounded-pill mr-2"
                  onClick={(e) => openComments(e)}
                  data-toggle="modal"
                  data-target={"#modalApps" + item.Codigo}
                ><i className="fas fa-comment"></i> </button>

                {is360 && (
                  <button
                    className="btn btn-outline-info btn-sm rounded-pill "
                    onClick={(e) => open360Modal(e)}
                    data-toggle="modal"
                    data-target={"#modal360" + item.idFamilia + index}
                  >
                    360
                  </button> 
                )}
                {isModal360Open && (
                  <Modal360Viewer
                    index={index}
                    item={item}
                    catalogo={true}
                    closeModal={close360Modal}
                  />
                )}
              </div>
              <div className="col-xs text-right">
                <span className={renderAvailability(item.Disponibilidad)}>
                  {item.Disponibilidad}
                </span>
              </div>
            </div>
          </h5>
        </div>
        {item.Descripcion ? (
          <div className="card-body card-text h-25">{item.Descripcion}</div>
        ) : (
          <ul className="list-group list-group-flush">
            {item.Modelo && (
              <li className="list-group-item">
                <strong>Modelo : </strong>
                {item.Modelo}
              </li>
            )}
            {item.Posicion && (
              <li className="list-group-item">
                <strong>Posición : </strong>
                {item.Posicion}
              </li>
            )}
            {item.Condicion && (
              <li className="list-group-item">
                <strong>Condición : </strong>
                {item.Condicion}
              </li>
            )}
            {item.Agrupacion &&
              item.TipoVehiculo.trim().toUpperCase() !==
                item.Agrupacion.trim().toUpperCase() && (
                <li className="list-group-item">
                  <strong>Tipo : </strong>
                  {item.Agrupacion.toUpperCase()}
                </li>
              )}
          </ul>
        )}
      </div>
    </div>
  );
};

export default CatalogueProductItem;
