import PrivacyNotice from "./PrivacyNotice";

const Footer = () => {
  return (
    <div>
      <footer className="footer">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-4">
              <span className="copyright">
                Copyright &copy; GRUPO GOVI -{" "}
                <span id="yearFooter">{new Date().getFullYear()}&nbsp;</span>
              </span>
            </div>
            <div className="col-md-4">
              <ul className="list-inline social-buttons">
                <li className="list-inline-item">
                  <a href="https://twitter.com/suspensiongrob" target="_new">
                    <i className="fab fa-twitter"></i>
                  </a>
                </li>
                <li className="list-inline-item">
                  <a
                    href="https://www.facebook.com/suspensiongrob"
                    target="_new"
                  >
                    <i className="fab fa-facebook-f"></i>
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-md-4">
              <ul className="list-inline quicklinks">
                <li className="list-inline-item">
                  <a href="#AvisoPrivacidad" data-toggle="modal" data-target="#avisoPrivacidad">
                    Aviso de Privacidad
                  </a>
                </li>
              </ul>
              <PrivacyNotice/>
            </div>
          </div>
        </div>
      </footer>      
    </div>
  );
};

export default Footer;
