import React from "react";

import { NavLink } from "react-router-dom";

const Menu = () => {
  return (
    <nav
      className="navbar navbar-expand-lg navbar-dark fixed-top navbar-shrink"
      name="mainNav"
      id="mainNav"
    >
      <div className="container">        
        <a className="navbar-brand js-scroll-trigger" href="/">
          <img src="img/logos/GROB-v2.png" alt="" className="img-fluid" />
        </a>
        
        <button
          className="navbar-toggler navbar-toggler-right fluid"
          type="button"
          data-toggle="collapse"
          data-target="#navbarResponsive"
          aria-controls="navbarResponsive"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          Menú
          <i className="fas fa-bars ml-2"></i>
        </button>
        <div className="collapse navbar-collapse" id="navbarResponsive">
          <ul className="navbar-nav text-uppercase ml-auto">
            <li className="nav-item">
              <NavLink
                to="/Services"
                className="nav-link js-scroll-trigger"
                activeClassName="nav-link active"
              >
                Servicios
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to="/Products"
                className="nav-link js-scroll-trigger"
                activeClassName="nav-link active"
              >
                Productos
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to="/Catalogue"
                className="nav-link js-scroll-trigger"
                activeClassName="nav-link active"
              >
                {" "}
                Catálogo
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to="/About"
                className="nav-link js-scroll-trigger"
                activeClassName="nav-link active"
              >
                {" "}
                Nosotros
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to="/Locations"
                className="nav-link js-scroll-trigger"
                activeClassName="nav-link active"
              >
                {" "}
                Ubicaciones
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to="/Contact"
                className="nav-link js-scroll-trigger"
                activeClassName="nav-link active"
              >
                {" "}
                Contacto
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Menu;
