import React, { useState, useEffect, useRef } from "react";
import Swal from "sweetalert";
import Filter from "bad-words";
import SimpleReactValidator from "simple-react-validator";
import { agregaComentarios } from "../Repository";

const Comments = (props) => {
  const codigo = props.codigo;
  const [comentarios, setComentarios] = useState("");
  const [, forceUpdate] = useState(0);
  let filter = new Filter();
 // const cerrarmodal = useState(props.closeModal);
  filter.addWords(
    "pendejo",
    "puñetas",
    "joto",
    "marica",
    "maricas",
    "jotos",
    "putitos",
    "weyes",
    "wey",
    "weys",
    "cagapalos",
    "mierda",
    "ojetes",
    "MARICONES",
    "CARAS DE VERGA",
    "CARA DE VERGA",
    "CAREVERGA",
    "mierdas",
    "mierda",
    "mierd4s",
    "mi3rd4s",
    "mi3rd45",
    "APAÑAR",
    "ARRABALERA",
    "ASESINAR",
    "ASESINO",
    "ASSHOLE",
    "BABOSA",
    "JALADAS",
    "BEARNER",
    "VERGAS",
    "MAMADAS",
    "BITCH",
    "BLOWJOB",
    "BOCATUBO",
    "BOLUDO",
    "BUBIS",
    "CABRON",
    "CACHAPERAS",
    "CAGADA",
    "CAGANTE",
    "CAGAR",
    "CAGON",
    "CARAJO",
    "CHINGA TU MADRE",
    "CHINGADA",
    "CHINGADAS",
    "CHINGADAZO",
    "CHINGADERA",
    "CHINGADO",
    "CHINGADOS",
    "CHINGAR",
    "CHINGASAVEINTE",
    "CHIRINGANDO",
    "CHUPABOLAS",
    "CHUPAMEDIDAS",
    "CLITORIS",
    "COCAINA",
    "COHECHO",
    "COJO",
    "COJONES",
    "COLOFOX",
    "COÑO",
    "COOK",
    "CULADOTE",
    "CULEADA",
    "CULERA",
    "CULERISIMO",
    "CULERO",
    "CULO",
    "CULOTE",
    "DICK",
    "DROGAS",
    "DUMBASS",
    "ENVERGADO",
    "ESTUPIDO",
    "ESVASTICA",
    "FACISMO",
    "FAGGOT",
    "FODASE",
    "FOLLADOR",
    "FOLLADOTA",
    "FOLLAR",
    "FORNICAR",
    "FRIGGIN",
    "FRIJOLERO",
    "FUCK",
    "FUCKER",
    "FUCKIN",
    "GARANTIA",
    "GARGAJO",
    "GILIPOLLAS",
    "GOLFA",
    "GUBERNAMENTAL",
    "GÜEVO",
    "HIJODEPUTA",
    "HORE",
    "HUEVON",
    "HUEVUDO",
    "IJUEPUTA",
    "INMIGRANTES",
    "JACK OFF",
    "JETON",
    "JODER",
    "JOTO",
    "JUEZ",
    "KU KLUX KLAN",
    "LADRONES",
    "LAMEHUEVOS",
    "LEGIONARIO",
    "LENON",
    "MAMADA",
    "MAMADOTA",
    "MAMAR",
    "MAMERTO",
    "MAMON",
    "MANFLORA",
    "MARICA",
    "MARICON",
    "MAIGUANA",
    "MARIMACHO",
    "MENTECATO",
    "MERDA",
    "MERDE",
    "MIERDA",
    "MIERDOTA",
    "MOTHEFUCKER",
    "MUERDE ALMOHADAS",
    "NARCO",
    "NAZISMO",
    "NECROFILIA",
    "OJETE",
    "OPIO",
    "PANOCHA",
    "PANOCHON",
    "PASON",
    "PEDO",
    "PELOTUDO",
    "PENDEJA",
    "PENDEJAZO",
    "PENDEJETE",
    "PENDEJISIMA",
    "PENDEJISIMO",
    "PENDEJO",
    "PERVERSO",
    "PHAT ASS",
    "PINCHE",
    "PINCHES",
    "PINCHISIMA",
    "PINCHISIMO",
    "PIPE",
    "PIRUJA",
    "PITO",
    "PITOTE",
    "POOP",
    "PUCHA ",
    "PUSSY",
    "PUTA",
    "PUTERA",
    "PUTISIMA",
    "PUTISIMO",
    "PUTITA",
    "PUTITO",
    "PUTO",
    "PUTODEMIERDA",
    "PUTON",
    "PUTOTA",
    "PUTOTE",
    "RACK",
    "RAMERAS",
    "RATERO",
    "RATONEAR",
    "SADICO",
    "SHIT",
    "SHON DES WEIBCHENS",
    "SICARIO",
    "SUASTICA",
    "SUICIDIO",
    "TACHA ",
    "TARADO",
    "TONTEJO",
    "TRAGALECHE",
    "VERGA",
    "VERGAMOTA",
    "VERGASOS",
    "VERGASTE",
    "VERGOTA",
    "VERGOTOTOTA",
    "VERGUITA",
    "VERIJA",
    "VERIJON",
    "WEY",
    "WHIMPY",
    "ZORRIPUTA",
    "ABORRECER",
    "AHORCADO",
    "AHORCAR",
    "AMPUTAR",
    "ANIQUILAR",
    "ANO",
    "APENDEJAR",
    "APUÑALAR",
    "ASESINAR",
    "ASESINAR",
    "ASESINATO",
    "ASESINO",
    "ASESINOS",
    "ASFIXIAR",
    "ASHOLE",
    "ASHOLES",
    "ASSHOLE",
    "ASSHOLES",
    "ATENTADO",
    "BELTRAN LEYVA",
    "BIN LADEN",
    "BLOWJOB",
    "BLOWJOBS",
    "CHAPO",
    "CHINGADA",
    "CHINGADAS",
    "CHINGADAZOS",
    "CHINGADERA",
    "CHINGADERAS",
    "CHINGADÍSIMO",
    "CHINGADÍSIMOS",
    "CHINGADOS",
    "CHINGAR",
    "CHINGAR ",
    "CHINGARS",
    "CHINGARSE",
    "CHINGARSES",
    "CHINGO",
    "CHUPABOLAS",
    "CHUPAMEDIAS",
    "CLITORIS",
    "COÑO",
    "COÑOS",
    "CULA",
    "CULADERO",
    "CULAZO",
    "CULEADO",
    "CULEAR",
    "CULEARSE",
    "CULEARSES",
    "CULEI",
    "CULEIS",
    "CULERÍSIMO",
    "CULERÍSIMOS",
    "CULERO",
    "CULEROS",
    "CULÍSIMO",
    "CULÍSIMOS",
    "CULO",
    "CULOS",
    "DICK",
    "DICKS",
    "DROGA",
    "ESVASTICA",
    "ESVASTICAS",
    "GENOCIDIO",
    "GUBERNAMENTAL",
    "GUBERNAMENTALES",
    "GUERRA",
    "HITLER",
    "HOLOCAUSTO",
    "HOLOCAUSTO",
    "MALCOGIDA",
    "MALDITA",
    "MALDITO",
    "MALDOSO",
    "MALPARIDO",
    "MARIHUANA",
    "MUTILAR",
    "NARCO",
    "NARCOTRAFICO",
    "NAZI",
    "ODIO",
    "OSAMA",
    "OSSAMA",
    "PENDEJADA",
    "PENDEJAS",
    "PENDEJAZO",
    "PENDEJEAR",
    "PENDEJERA",
    "PENDEJITA",
    "PENDEJITAS",
    "PENDEJITO",
    "PENDEJITOS",
    "PENDEJOS",
    "PEPAZO",
    "PERESOZA",
    "PERESOZOS",
    "PEREZOSAS",
    "PEREZOSO",
    "PERSECUCIÓN",
    "PROSTITUCIÓN",
    "PUCHAS",
    "PUNJETAS",
    "PUÑETA",
    "PUÑETAS",
    "PUÑETERIA",
    "PUSSY",
    "PUSSYS",
    "PUTAMADRE",
    "PUTAMADRES",
    "PUTARRÓN",
    "PUTAS",
    "PUTATIVO",
    "PUTAZO",
    "PUTAZOS",
    "PUTEADA",
    "PUTEAR",
    "PUTEARS",
    "PUTEARSE",
    "PUTEARSES",
    "PUTERIAS",
    "PUTERIO",
    "PUTESA",
    "PUTIFERA",
    "PUTIMAS",
    "PUTIN",
    "PUTÍSIMA",
    "PUTÍSIMAS",
    "PUTÍSIMO",
    "PUTÍSIMOS",
    "PUTITA",
    "PUTITAS",
    "PUTÓN",
    "PUTOS",
    "QULO",
    "RE-PUTA",
    "RE-PUTAS",
    "SICARIO",
    "SICARIOS",
    "SIDOSO",
    "SIDOSOS",
    "SIFILÍTICO",
    "SIFILÍTICOS",
    "SOCIALDEMOCRACIA",
    "SOCIALISTA",
    "SOHN DES WEIBCHENS",
    "SOPLANUCAS",
    "SUASTICA",
    "SUASTICAS",
    "SUICIDIO",
    "SUICIDIOS",
    "TERRORISMO",
    "TERRORISTA",
    "TESTICULOS",
    "TORTURAR",
    "VAGINA",
    "ABUSAR",
    "AGRAVIAR",
    "AGREDIR",
    "AMATAR",
    "ASALTAR",
    "ASESINAR",
    "ASESINARS",
    "BIOLAR",
    "DAÑAR",
    "GOLPEAR",
    "HERIR",
    "INSULTAR",
    "INSULTO",
    "LASTIMAR",
    "MALTRATAR",
    "MATAR",
    "MATARS",
    "MUTILAR ",
    "OFENDER",
    "TORTURAR",
    "VIOLACIÓN",
    "VIOLAR"
  );

  

  const validator = useRef(
    new SimpleReactValidator({
      element: (message) => (
        <span className="text-danger"  >
          <i className="fa fa-exclamation-circle" aria-hidden="true"  />
          {` ${message}`}
        </span>
      ),
      messages: {
        default: "Campo Requerido",
      },
    })
  );

  useEffect(() => {
 
  }, []);

  const enviarComentario = (e) => {
    e.preventDefault();
    if (validator.current.allValid()) {
     // console.log(filter.clean(comentarios));
      var dateTime = new Date();
      agregaComentarios("GROBMX", codigo, dateTime, filter.clean(comentarios))
        .then((response) => {
          Swal("Aviso", "Comentarios enviados correctamente.", "info");
          setComentarios("");
          validator.current.hideMessages();
        })
        .catch((err) => {
          Swal("Error", err, "error");
        });
      
      forceUpdate(0);
    } else {
      Swal("Aviso", "Favor de validar la información", "warning");
      validator.current.showMessages();
      forceUpdate(1);
    }
  };

  return (
    <div
      className="modal fade"
      id={"modalApps" + codigo}
      data-backdrop="static"
      data-keyboard="false"
      tabIndex="-1"
      aria-labelledby={"modalApps" + codigo + "Label"}
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h5
              className="modal-title text-center"
              id={"modalApps" + codigo + "Label"}
            >
              <span className="badge badge-warning ml-1 mr-1">
              
              </span>
              <small>
                Agregar un comentario&nbsp;de:&nbsp;
              </small>
              <span className="badge badge-secondary ml-1 mr-1">{codigo}</span>
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={props.closeModal}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="container-fluid">
              <div className="row">
                {/* <p className="help-block text-danger"></p> */}
                <textarea
                  className="form-control"
                  id="comentarios"
                  name="comentarios"
                  placeholder="Comentarios *"
                  rows="4"
                  value={comentarios}
                  required="required"
                  onChange={(e) => {
                    setComentarios(e.target.value);    
                  }}
                  data-validation-required-message="Capture sus comentarios."
                ></textarea>
                {validator.current.message(
                  "comentarios",
                  comentarios,
                  "required|string"
                )}
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              onClick={
                (e) => { enviarComentario(e); }} 
            >
              Enviar
            </button>
            <button
              type="button"
              className="btn btn-secondary"
              data-dismiss="modal"
              onClick={props.closeModal}
            >
              Cerrar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Comments;
