import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import Home from "./screens/Home";
import Services from "./screens/Services";
import Products from "./screens/Products";
import Catalogue from "./screens/Catalogue";
import About from "./screens/About";
import Locations from "./screens/Locations";
import Contact from "./screens/Contact";

class Router extends Component {
  render() {
    return (
      <BrowserRouter>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/Services" component={Services} />
          <Route exact path="/Products" component={Products} />
          <Route exact path="/Catalogue" component={Catalogue} />
          <Route exact path="/About" component={About} />
          <Route exact path="/Locations" component={Locations} />
          <Route exact path="/Contact" component={Contact} />
        </Switch>
      </BrowserRouter>
    );
  }
}

export default Router;
