import React, { Component, Fragment } from "react";

import Menu from "../components/Menu";
import Footer from "../components/Footer";

class Home extends Component {
  render() {
    return (
      <Fragment>
        <Menu />
        <header className="masthead">
          <div className="container">
            <div className="intro-text">
              <div className="intro-lead-in">
                Especialistas en Suspensión, Dirección y Tracción Automotriz
              </div>
              <div className="intro-heading text-uppercase">Desde 1980</div>
              <a
                className="btn btn-primary btn-xl text-uppercase js-scroll-trigger"
                href="/Services"
              >
                Ver más
              </a>
            </div>
          </div>
        </header>        
        <Footer/>
      </Fragment>
    );
  }
}

export default Home;
