import React, { useState, useEffect, useRef, Fragment } from "react";
import SimpleReactValidator from "simple-react-validator";
import Swal from "sweetalert";

import {
  getConfig,
  sendMail,
  getDepartamentos,
  getEstados,
  comprobarCaptcha,
} from "../Repository";
import Menu from "../components/Menu";
import Footer from "../components/Footer";
import Recaptcha from "react-recaptcha";
import Global from "../global";

const Contact = () => {
  const [eMailSender, setEmailSender] = useState("");
  const [eMailSenderPwd, setEmailSenderPwd] = useState("");
  const [eMailReceiver, setEmailReceiver] = useState("");
  const [nombre, setNombre] = useState("");
  const [telefono, setTelefono] = useState("");
  const [comentarios, setComentarios] = useState("");
  const [deptoDestino, setDeptoDestino] = useState([]);
  const [estados, setEstados] = useState([]);
  const [departamento, setDepartamento] = useState(0);
  const [estado, setEstado] = useState(0);
  const [defeatChallenge, setDefeatChallenge] = useState(0);
  const [ciudad, setCiudad] = useState("");
  const [correo, setCorreo] = useState("");
  const [, forceUpdate] = useState(0);
  const recaptcha = useRef(new Recaptcha());

  const validator = useRef(
    new SimpleReactValidator({
      element: (message) => (
        <span className="text-danger">
          <i className="fa fa-exclamation-circle" aria-hidden="true" />
          {` ${message}`}
        </span>
      ),
      messages: {
        default: "Campo Requerido",
        email: "Correo invalido",
      },
    })
  );

  function ValidaEMailEnviado() {
    validator.current.hideMessages();
    Swal("Aviso", "Correo enviado.", "info");
    var cboDpto = document.getElementsByName("departamento")[0];
    var cboEstado = document.getElementsByName("estado")[0];
    cboEstado.value = 0;
    cboDpto.value = 0;
    setDefeatChallenge(0);
    setNombre("");
    setTelefono("");
    setCorreo("");
    setComentarios("");
    setDepartamento("");
    setEstado("");
    setDefeatChallenge(0);
    setCiudad("");
    resetRecaptcha();
    forceUpdate(0);
  }

  useEffect(() => {
    obtieneConfig("eMailSender");
    obtieneConfig("eMailSenderPwd");
    obtieneDepartamentos();
    obtieneEstados();
    recaptcha.current.render();
    validator.current.hideMessages();
  }, []);

  function resetRecaptcha() {
    recaptcha.current.reset();
    setDefeatChallenge(0);
  }

  async function compruebaCaptchaWeb(e) {
    var respuesta = await comprobarCaptcha(e);

    if (respuesta.status === 200) {
      setDefeatChallenge(1);
    }
  }
  function enviarEMail(e) {
    e.preventDefault();

    if (validator.current.allValid() && defeatChallenge === 1) {
      sendMail(
        eMailSender,
        eMailSenderPwd,
        eMailReceiver,
        nombre,
        correo,
        telefono,
        comentarios,
        estado,
        ciudad
      ).then((res) => {
        if (res === 0) {
          ValidaEMailEnviado();
        }
        if (res === 1) {
          Swal("Error", "Error al enviar el correo.", "error");
        }
      });
    } else {
      Swal("Aviso", "Favor de validar la información", "warning");
      validator.current.showMessages();
      forceUpdate(1);
    }
  }
  function obtieneDepartamentos() {
    getDepartamentos()
      .then((response) => {
        if (response.data.status === "success") {
          var items = response.data.result.map((item) => {
            return { value: item.idCatalogoDepto, text: item.descripcion };
          });
          setDeptoDestino(
            [{ value: 0, text: "Seleccione [Departamento]" }].concat(items)
          );
        } else {
          Swal(
            "Error obtener la información [Departamentos]",
            response.message,
            "error"
          );
        }
      })
      .catch((err) =>
        Swal("Error obtener la información [Departamentos]", err, "error")
      );
  }

  function obtieneEstados() {
    getEstados()
      .then((response) => {
        if (response.data.status === "success") {
          var items = response.data.result.map((item) => {
            return { value: item.descripcion, text: item.descripcion };
          });
          setEstados([{ value: 0, text: "[Seleccione Estado]" }].concat(items));
        } else {
          Swal(
            "Error obtener la información [Estados]",
            response.message,
            "error"
          );
        }
      })
      .catch((err) =>
        Swal("Error obtener la información [Estados]", err, "error")
      );
  }

  function obtieneConfig(config) {
    if (config !== 0) {
      getConfig(config).then((response) => {
        if (
          response.data.status === "success" &&
          response.data.result.length > 0
        ) {
          switch (response.data.result[0].key) {
            case "eMailSender":
              setEmailSender(response.data.result[0].value);
              break;
            case "eMailSenderPwd":
              setEmailSenderPwd(response.data.result[0].value);
              break;
            default:
              setEmailReceiver(response.data.result[0].value);
              break;
          }
        } else {
          console.log("Information not found.");
        }
      });
    }
  }
  return (
    <Fragment>
      <Menu />
      <section className="page-section" id="contact">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <h2 className="section-heading text-uppercase">Contacto</h2>
              <h3 className="section-subheading mb-4 text-muted">
                {Global.leyendaContacto}
              </h3>
            </div>
          </div>
          <div className="row">
            <div className="container col-lg-12">
              <form
                id="contactForm"
                name="sentMessage"
                action="?"
                method="POST"
                noValidate="novalidate"
              >
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <select
                          name="departamento"
                          key="departamento"
                          className="form-control"
                          onChange={(e) => {
                            setDepartamento(e.target.value);
                            obtieneConfig(e.target.value);
                          }}
                        >
                          {deptoDestino.map((item, index) => (
                            <option key={index} value={item.value}>
                              {item.text}
                            </option>
                          ))}
                        </select>
                        {validator.current.message(
                          "departamento",
                          departamento,
                          "required|string|min:3|max:50"
                        )}
                      </div>
                      <input
                        className="form-control"
                        id="nombre"
                        name="nombre"
                        type="text"
                        placeholder="Nombre *"
                        value={nombre}
                        required="required"
                        onChange={(e) => {
                          setNombre(e.target.value);
                        }}
                        data-validation-required-message="Capture su nombre."
                      />
                      {validator.current.message(
                        "nombre",
                        nombre,
                        "required|string"
                      )}
                      <p className="help-block text-danger"></p>
                      <select
                        name="estado"
                        key="estado"
                        className="form-control"
                        onChange={(e) => setEstado(e.target.value)}
                      >
                        {estados.map((item, index) => (
                          <option key={index} value={item.value}>
                            {item.text}
                          </option>
                        ))}
                      </select>
                      {validator.current.message(
                        "estado",
                        estado,
                        "required|string|min:3|max:50"
                      )}
                      <p className="help-block text-danger"></p>
                      <input
                        className="form-control"
                        id="ciudad"
                        name="ciudad"
                        type="text"
                        placeholder="Ciudad *"
                        value={ciudad}
                        required="required"
                        onChange={(e) => setCiudad(e.target.value)}
                        data-validation-required-message="Capture su nombre."
                      />
                      {validator.current.message(
                        "nombre",
                        ciudad,
                        "required|string"
                      )}
                      <p className="help-block text-danger"></p>
                      <input
                        className="form-control"
                        id="correo"
                        name="correo"
                        type="email"
                        placeholder="Email *"
                        value={correo}
                        required="required"
                        onChange={(e) => setCorreo(e.target.value)}
                        data-validation-required-message="Capture su email."
                      />
                      {validator.current.message(
                        "correo",
                        correo,
                        "required|email"
                      )}
                      <p className="help-block text-danger"></p>
                    </div>
                    <div className="col-md-6">
                      <input
                        className="form-control"
                        id="telefono"
                        name="telefono"
                        type="tel"
                        placeholder="Teléfono *"
                        value={telefono}
                        required="required"
                        onChange={(e) => setTelefono(e.target.value)}
                        data-validation-required-message="Capture su teléfono."
                      />
                      {validator.current.message(
                        "telefono",
                        telefono,
                        "required|string"
                      )}
                      <p className="help-block text-danger"></p>
                      <textarea
                        className="form-control"
                        id="comentarios"
                        name="comentarios"
                        placeholder="Comentarios *"
                        rows="4"
                        value={comentarios}
                        required="required"
                        onChange={(e) => setComentarios(e.target.value)}
                        data-validation-required-message="Capture sus Comentarios."
                      ></textarea>
                      {validator.current.message(
                        "comentarios",
                        comentarios,
                        "required|string"
                      )}
                      <p className="help-block text-danger"></p>
                      <div className="d-flex">
                        
                        <Recaptcha
                          ref={recaptcha}
                          name="defeatChallenge"
                          sitekey={Global.siteKeyClient}
                          hl="es"
                          size="normal"
                          render="explicit"
                          theme="dark"
                          onloadCallback={(e) => {
                            setDefeatChallenge(0);
                          }}
                          verifyCallback={(e) => {
                            compruebaCaptchaWeb(e);
                          }}
                          expiredCallback={(e) => {
                            setDefeatChallenge(0);
                          }}
                        />
                        
                        <p className="help-block text-danger"></p>
                      </div>
                    </div>
                    <div className="clearfix"></div>
                    <div className="col-lg-12 mt-4 text-center">
                      <div id="success"></div>
                      <button
                        id="sendMessageButton"
                        onClick={(e) => enviarEMail(e)}
                        className="btn btn-primary btn-xl text-uppercase"
                        type="submit"
                      >
                        Enviar
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </Fragment>
  );
};

export default Contact;
