import React, { Fragment, useEffect, useState, useRef } from "react";
import SimpleReactValidator from "simple-react-validator";
import Swal from "sweetalert";

import CatalogueProductItem from "../components/CatalogueProductItem";
import {
  getMarcas,
  getVehiculos,
  getModelos,
  getFamilias,
  getProductos,
} from "../Repository";

const SearchByMaker = () => {
  const [status, setStatus] = useState(null);
  const [idMarca, setIdMarca] = useState(0);
  const [idVehiculo, setIdVehiculo] = useState(0);
  const [idModelo, setIdModelo] = useState(0);
  const [idFamilia, setIdFamilia] = useState(-1);
  const [marcas, setMarcas] = useState([]);
  const [vehiculos, setVehiculos] = useState([]);
  const [modelos, setModelos] = useState([]);
  const [familias, setFamilias] = useState([]);
  const [productos, setProductos] = useState([]);
  const [loading, setLoading] = useState(0);

  const validator = useRef(
    new SimpleReactValidator({
      element: (message) => (
        <span className="text-danger">
          <i className="fa fa-exclamation-circle" aria-hidden="true" />
          {` ${message}`}
        </span>
      ),
      messages: {
        default: "Campo Requerido",
        email: "Correo invalido",
      },
    })
  );

  useEffect(() => {
    obtieneMarcas();
    validator.current.hideMessages();
  }, []);

  const handleComboChange = (event) => {
    if (event.target.name === "idMarca") {
      setIdMarca(event.target.value);
      setIdVehiculo(0);
      setVehiculos([]);
      setIdModelo(0);
      setIdFamilia(-1);
      setFamilias([]);
      setProductos([]);
      setStatus(null);
      obtieneVehiculos(event.target.value);
      obtieneModelos(0);
      obtieneFamilias(0, 0);
    }
    if (event.target.name === "idVehiculo") {
      setIdVehiculo(event.target.value);
      setModelos([]);
      setIdFamilia(-1);
      setFamilias([]);
      setProductos([]);
      setStatus(null);
      obtieneModelos(event.target.value);
      obtieneFamilias(0, 0);
    }
    if (event.target.name === "idModelo") {
      setIdModelo(event.target.value);
      setIdFamilia(-1);
      setFamilias([]);
      setProductos([]);
      setStatus(null);
      obtieneFamilias(idVehiculo, event.target.value);
    }
    if (event.target.name === "idFamilia") {
      setIdFamilia(event.target.value);
      setProductos([]);
      setStatus(null);
    }
  };

  const obtieneMarcas = () => {
    getMarcas()
      .then((response) => {
        if (response.status === "success") {
          var items = response.result.map((item) => {
            return { value: item.idMarca, text: item.Marca };
          });
          setMarcas([{ value: 0, text: "[Seleccione]" }].concat(items));
        } else {
          Swal(
            "Error obtener la información [Marcas]",
            response.message,
            "error"
          );
        }
      })
      .catch((err) =>
        Swal("Error obtener la información [Marcas]", err, "error")
      );
  };

  const obtieneVehiculos = (idMarca) => {
    getVehiculos(idMarca)
      .then((response) => {
        if (response.status === "success") {
          var items = response.result.map((item) => {
            return { value: item.idTipoVehiculo, text: item.TipoVehiculo };
          });
          setVehiculos(
            [
              { value: 0, text: items.length > 0 ? "[Seleccione]" : "[---]" },
            ].concat(items)
          );
        } else {
          Swal(
            "Error obtener la información [Vehículos]",
            response.message,
            "error"
          );
        }
      })
      .catch((err) =>
        Swal("Error obtener la información [Vehículos]", err, "error")
      );
  };

  const obtieneModelos = (idVehiculo) => {
    getModelos(idVehiculo)
      .then((response) => {
        if (response.status === "success") {
          var items = response.result.map((item) => {
            return { value: item.Modelo, text: item.Modelo };
          });
          setModelos(
            [
              { value: 0, text: items.length > 0 ? "[Seleccione]" : "[---]" },
            ].concat(items)
          );
        } else {
          Swal(
            "Error obtener la información [Modelos]",
            response.message,
            "error"
          );
        }
      })
      .catch((err) =>
        Swal("Error obtener la información [Modelos]", err, "error")
      );
  };

  const obtieneFamilias = (idVehiculo, idModelo) => {
    getFamilias(idVehiculo, idModelo)
      .then((response) => {
        if (response.status === "success") {
          var items = {};
          if (idVehiculo > 0 && idModelo > 0)
            items = response.result.map((item) => {
              return { value: item.idFamilia, text: item.Familia };
            });
          setFamilias(
            [
              { value: -1, text: items.length > 0 ? "[Seleccione]" : "[---]" },
            ].concat(items)
          );
        } else {
          Swal(
            "Error obtener la información [Familias]",
            response.message,
            "error"
          );
        }
      })
      .catch((err) =>
        Swal("Error obtener la información [Familias]", err, "error")
      );
  };

  //Search Products
  const search = (e) => {
    e.preventDefault();
    setLoading(1);
    setProductos([]);
    setStatus(null);
    if (validator.current.allValid()) {
      getProductos(idVehiculo, idFamilia, idModelo)
        .then((response) => {
          if (response.status === "success") {
            setStatus("success");
            setProductos(response.result);
            setLoading(0);
          } else {
            setLoading(0);
            Swal(
              "Error obtener la información [Productos]",
              response.message,
              "error"
            );
          }
        })
        .catch((err) => {
          setLoading(0);
          Swal("Error obtener la información [Productos]", err, "error");
        });
    } else {
      validator.current.showMessages();
      setLoading(0);
    }
  };

  return (
    <div>
      <form onSubmit={search}>
        <div className="container">
          <div className="row mt-3">
            <div className="col-md-3 mb-3">
              Marca:
              <select
                name="idMarca"
                key="idMarca"
                className="form-control"
                onChange={handleComboChange}
              >
                {marcas.map((item, index) => (
                  <option key={index} value={item.value}>
                    {item.text}
                  </option>
                ))}
              </select>
              {validator.current.message(
                "idMarca",
                idMarca,
                "required|numeric|min:1,num"
              )}
            </div>
            <div className="col-md-3 mb-3">
              Vehículo:
              <select
                name="idVehiculo"
                key="idVehiculo"
                className="form-control"
                onChange={handleComboChange}
              >
                {vehiculos.map((item, index) => (
                  <option key={index} value={item.value}>
                    {item.text}
                  </option>
                ))}
              </select>
              {validator.current.message(
                "idVehiculo",
                idVehiculo,
                "required|numeric|min:1,num"
              )}
            </div>
            <div className="col-md-3 mb-3">
              Modelo:
              <select
                name="idModelo"
                key="idModelo"
                className="form-control"
                onChange={handleComboChange}
              >
                {modelos.map((item, index) => (
                  <option key={index} value={item.value}>
                    {item.text}
                  </option>
                ))}
              </select>
              {validator.current.message(
                "idModelo",
                idModelo,
                "required|numeric|min:1,num"
              )}
            </div>
            <div className="col-md-3">
              Familia:
              <select
                name="idFamilia"
                key="idFamilia"
                className="form-control"
                onChange={handleComboChange}
              >
                {familias.map((item, index) => (
                  <option key={index} value={item.value}>
                    {item.text}
                  </option>
                ))}
              </select>
              {validator.current.message(
                "idFamilia",
                idFamilia,
                "required|numeric|min:0,num"
              )}
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-md-9"></div>
            <div className="col-md-3">
              <button
                className="btn btn-warning btn-block font-weight-normal"
                type="submit"
              >
                <i className="fas fa-search mr-1"></i>Buscar
              </button>
            </div>
          </div>
          {loading === 1 && (
            <div className="text-center">
              <p>Cargando...</p>
              <div
                className="spinner-border text-warning"
                role="status"
                aria-hidden="true"
              ></div>
            </div>
          )}
          {status === "success" && (
            <Fragment>
              <div className="row mt-3">
                <div className="col-md-3"></div>
                <div className="col-md-6 text-center">
                  <h6>
                    <span className="badge badge-warning ml-1 mr-1">
                      {productos.length}
                    </span>
                    <small>
                      <strong>Producto(s) encontrado(s)</strong>
                    </small>
                  </h6>
                  <div className="col-md-3"></div>
                </div>
              </div>
              <div className="row">
                {productos.map((item, index) => (
                  <CatalogueProductItem item={item} key={index} index={index} />
                ))}
              </div>
            </Fragment>
          )}
        </div>
      </form>
    </div>
  );
};

export default SearchByMaker;
